import Main from './Main';

import MainStatus from './MainStatus';
import Components from './Components';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function Routing() {
    return (<Router>
        <Switch>
            <Route path="/components">
                <Components />
            </Route>
            <Route path="/status">
                <MainStatus fullPage="true" />
            </Route>
            <Route path="/">
                <Main />
            </Route>
        </Switch>
    </Router>);
}

export default Routing;
