import { Component } from 'react';

import ls from 'local-storage';
import baseURL from '../baseURL';

var indicatorMessages = {'resolved':'good', 'investigating':'minor', 'critical':'critical', 'maintenance':'maintenance'};

class Message extends Component {
    render() {
        const classNames = 'status-box ' + this.props.status + '-message ';
        var classLoc = 'message-general';
        if(this.props.isFirst){
            classLoc = 'message-first';
        }else if (this.props.isLast) {
            classLoc = 'message-last';
        }
        return (<div className={classLoc}><div className={classNames}><span className="message-status"><div className="right">{this.props.status.toLowerCase()}</div></span></div> <div className="text-margin">{this.props.message}<br /><span className="date empty">{this.props.time}</span></div></div>);
    }
}


class Messages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            tempIncidents: {}
        };

        this.days = 7;

        if ("connection" in navigator) {
            if (navigator.connection.saveData === true) { this.days = 7; }
        }
    }

    componentDidMount() {
        fetch(baseURL + "/api/v2/incidents.json")
            .then(res => res.json())
            .then(
                (result) => {
                    ls.set('messageLoad', true);
                    ls.set('message', result);
                    this.setState({
                        isLoaded: true,
                        tempIncidents: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    ms(mess){
        var options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };

        var weekOld = new Date();
        weekOld.setDate(weekOld.getDate() - this.days);
        var incidents = mess["incidents"].filter(function(incident){ return new Date(incident["created_at"]) > weekOld; });

        if(incidents.length === 0){
            return <div className="empty padding-none"><div className="font-36 margin-bottom">All good.</div><div className="font-12">Nothing to see here folks. Looks like GitHub is up and running and has been stable for quite some time.<br /><br />Now get back to work!</div></div>;
        }

        const out = [];
        for(var i = 0; i < incidents.length; i++){
            if(new Date(incidents[i]["created_at"]) < weekOld){ break; }
            if(incidents[i]["incident_updates"].length > 0){
                for(var j = 0; j < incidents[i]["incident_updates"].length; j++){
                    var w = (incidents[i]["incident_updates"][j]["status"] === "resolved" ? "good" : (incidents[i]["impact"] === 'none' ? 'good' : incidents[i]["impact"]));
                    if(w === undefined){ w = indicatorMessages[incidents[i]["incident_updates"][j]["status"]]; }

                    var date = new Date(mess["incidents"][i]["incident_updates"][j].created_at).toLocaleDateString("en-US", options);
                    var body = mess["incidents"][i]["incident_updates"][j].body;

                    out.push(<Message key={mess["incidents"][i]["incident_updates"][j].id} isFirst={(i === 0 && j === 0)} isLast={(i === incidents.length-1 && j === incidents[i]["incident_updates"].length-1)} status={w} message={body} time={date} />);
                }
            }
        }

        return out;
    }

    render() {
        const { error, isLoaded, tempIncidents } = this.state;
        if(error){
            return <div id="mainStatus" className="status-full bold error status-color"><span className="center-status">ERROR</span></div>;
        }else if(!isLoaded){
            return <div className="empty padding-none"><div className="font-36 margin-bottom">All good.</div><div className="font-12">Nothing to see here folks. Looks like GitHub is up and running and has been stable for quite some time.<br /><br />Now get back to work!</div></div>;
        }

        var mess = tempIncidents;

        return (<div id="messages" className="messages">
            {this.ms(mess)}
        </div>);
    }
}

export default Messages;
