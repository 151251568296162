import React from 'react';
import ReactDOM from 'react-dom';

import { Component } from 'react';

import './Main.css';
import Routing from './Routing';
import reportWebVitals from './reportWebVitals';

class Index extends Component {
    render() {
        return <Routing />;
    }
}

ReactDOM.render(
    <React.StrictMode>
        <Index />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
