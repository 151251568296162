import { Component } from 'react';

import baseURL from './baseURL';
import Status from './components/Status';

class Components extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            components: []
        };
    }

    componentDidMount() {
        fetch(baseURL + "/api/v2/components.json")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        components: result.components
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const { error, isLoaded, components } = this.state;
        var indicatorVals = {'operational':'good', 'degraded_performance':'minor', 'partial_outage':'major', 'major_outage':'critical', 'error':'error'}
        if(error){
            return <div id="mainStatus" className="status-full bold error status-color"><span className="center-status">ERROR</span></div>;
        }else if(!isLoaded){
            return <div className="status-full bold unavailable status-color"><span className="center-status">Loading...</span></div>;
        }

        var out = [];
        var comps = components.filter(component => !('Visit' === component.name.substring(0,5)));

        for(var i = 0; i < comps.length; i++){
            out.push(<Status key={comps[i].id} status={indicatorVals[comps[i].status]} name={comps[i].name} isComponent="true"/>);
        }

        return out;
    }
}

export default Components;
