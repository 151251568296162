import { Component } from 'react';

import ls from 'local-storage';
import baseURL from './baseURL';
import Status from './components/Status';

class MainStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            status: ''
        };
    }

    componentDidMount() {
        // if(ls.get('statusLoad') === true){
        //     this.setState({
        //         isLoaded: true,
        //         status: ls.get('status')
        //     });
        //     console.log(ls.get('status'));
        // }

        fetch(baseURL + "/api/v2/status.json")
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.status.indicator === 'none'){ result.status.indicator = 'good'; }
                    ls.set('statusLoad', true);
                    ls.set('status', result.status.indicator);
                    this.setState({
                        isLoaded: true,
                        status: result.status.indicator
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const { error, isLoaded, status } = this.state;
        
        console.log(ls.get('status'));

        if(error){
            return <div id="mainStatus" className="status-full bold error status-color"><span className="center-status">ERROR</span></div>;
        }
        if(!isLoaded){
            return <Status id="mainStatus" isUnavailable="true" />
        }
        return <Status id="mainStatus" status={status} fullPage={this.props.fullPage} />
    }
}

export default MainStatus;
