import { Component } from 'react';

import ls from 'local-storage';
import Fetcher from './Fetcher';
import baseURL from './baseURL';

import Error from './components/Error';
// import Loading from './components/Loading';
// import MainStatus from './components/MainStatus';
import Status from './components/Status';
import Messages from './components/Messages';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            statusLoad: false,
            messageLoad: false,
            status: '',
            tempIncidents: []
        };

        this.f = new Fetcher();
    }

    componentDidMount() {
        // this.f.getMain();
        // this.f.getSummary();
        // this.f.getStatus();
        
        fetch(baseURL + "/api/v2/status.json")
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.status.indicator === 'none'){ result.status.indicator = 'good'; }
                    ls.set('statusLoad', true);
                    ls.set('status', result.status.indicator);
                    this.setState({
                        statusLoad: true,
                        status: result.status.indicator
                    });
                },
                (error) => {
                    this.setState({
                        statusLoad: false,
                        error
                    });
                }
            )
    }

    render(){
        if(!navigator.onLine){
            return <Error />;
        }
        
        return (
            <div id="main" className="zed size-zero">
                <Status id="mainStatus" status={this.state.status} />
                <Messages />
            </div>
        );
        
        // return (
        //     <div id="main" className="zed size-zero">
        //         <MainStatus />
        //         <Messages />
        //     </div>
        // );
    }
}

export default Main;
