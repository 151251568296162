import ls from 'local-storage';

import baseURL from './baseURL';

class Fetcher {
    constructor(){
        ls.set('statusLoad', false);
        ls.set('status', 'unavailable');

        ls.set('messageLoad', false);
        ls.set('message', {});

        ls.set('componentLoad', false);
        ls.set('component', []);
    }

    getSummary(){
        fetch(baseURL + "/api/v2/summary.json")
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.status.indicator === 'none'){ result.status.indicator = 'good'; }
                    ls.set('statusLoad', true);
                    ls.set('status', result.status.indicator);

                    ls.set('messageLoad', true);
                    ls.set('message', {'incidents': result.incidents});

                    ls.set('componentLoad', true);
                    ls.set('component', {'components': result.components});
                },
                (error) => {
                    ls.set('statusLoad', false);
                    ls.set('status', 'error');

                    ls.set('messageLoad', false);
                    ls.set('message', []);

                    ls.set('componentLoad', false);
                    ls.set('component', []);
                }
            );
    }
    
    getStatus(){
        fetch(baseURL + "/api/v2/status.json")
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.status.indicator === 'none'){ result.status.indicator = 'good'; }
                    ls.set('statusLoad', true);
                    ls.set('status', result.status.indicator);
                },
                (error) => {
                    ls.set('statusLoad', false);
                    ls.set('status', 'error');
                }
            );
    }

    getMain(){
        fetch(baseURL + "/api/v2/status.json")
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.status.indicator === 'none'){ result.status.indicator = 'good'; }
                    ls.set('statusLoad', true);
                    ls.set('status', result.status.indicator);
                },
                (error) => {
                    ls.set('statusLoad', false);
                    ls.set('status', 'error');
                }
            );
        fetch(baseURL + "/api/v2/incidents.json")
            .then(res => res.json())
            .then(
                (result) => {
                    ls.set('messageLoad', true);
                    ls.set('message', result);
                },
                (error) => {
                    ls.set('messageLoad', false);
                    ls.set('message', []);
                }
            );
        fetch(baseURL + "/api/v2/components.json")
            .then(res => res.json())
            .then(
                (result) => {
                    ls.set('componentLoad', true);
                    ls.set('component', result);
                },
                (error) => {
                    ls.set('componentLoad', false);
                    ls.set('component', []);
                }
            );
    }
}

export default Fetcher;
