import { Component } from 'react';

class Status extends Component {
    render() {
        var status = "unavailable";
        var spanClass = 'center-status';

        if(this.props.isUnavailable === "true"){
            status = "unavailable";
            spanClass += ' font-36';
        }else if(this.props.status){
            status = this.props.status;
        }

        var classNames = 'status-height status-width bold status-color ' + status;

        if(this.props.isLoading && this.props.isLoading === "true"){
            spanClass += ' loading';
            return <div className="status-full status-width"><div className="center-status loading"></div></div>;
        }

        if(this.props.fullPage === "true"){
            classNames = 'status-full bold status-color ' + status;
        }
        if(this.props.isComponent && this.props.isComponent === "true"){
            classNames = 'status-height-comp no-shadow status-width bold status-color ' + status;

            return <div className={classNames}><span className={spanClass}>{this.props.name}</span></div>;
        }
        var id = this.props.id ? this.props.id : 'mainStatus';

        return <div id={id} className={classNames}><span className={spanClass}>{status.toUpperCase()}</span></div>;
    }
}

export default Status;
